import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import { Form, Button } from 'react-bootstrap'
import ReactPaginate from 'react-paginate';
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'
import { Upload, CloudDownloadFill } from 'react-bootstrap-icons';
import { saveAs } from 'file-saver'

// import BeatLoader from "react-spinners/BeatLoader";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)
// FilePond.registerPlugin(FilePondPluginFileValidateType);

const Orders = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token')
    const [offset, setOffset] = useState(1);
    const [fromDate, setFromDate] = useState(); //new Date().toISOString().slice(0, 10) '2020-01-01'
    const [toDate, setToDate] = useState();
    const [sortBy, setSortBy] = useState(-1);
    const [searchQuery, setSearchQuery] = useState('');
    const [contentLoading, setContentLoading] = useState(false);
    const [allOrders, setAllOrders] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [ordersPerPage, setOrdersPerPage] = useState(30)
    const [orderDetails, setOrderDetails] = useState([]);
    const [lgshow, setShow] = useState(false);
    const [filelgshow, setFileShow] = useState(false);
    const [modalTitle, setModalTitle] = useState('View Order Details');
    const [filemodalTitle, setFileModalTitle] = useState('Upload Result');
    const [viewMode, setViewMode] = useState(false);
    const [files, setFiles] = useState([])
    const [orderId, setOrderId] = useState('');
    
    useEffect(() => {
        getOrders();
    }, [])


    const getOrderData = (data, offset) => {
        return (
            data.map(order =>{
                const order_data = order.order_details[0];
                const patient_details = order.patient;
            
                return (
                <tr key={order_data._id}>
                    <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                        </div>
                    </td>
                    <td>
                        {order_data?.order_id}
                    </td>
                    <td>
                        <div className='d-flex align-items-center'>
                            <div className='d-flex justify-content-start flex-column'>
                                {patient_details?.full_name} 
                            </div>
                        </div>
                    </td>
                    <td>
                        {formatDate(order_data?.created_at)}
                    </td>
                    <td>
                        <Form.Group controlId="formBasicStatus" className='mt-5'>
                            <Form.Select aria-label="Default select example" className='form-select-sm' value={order_data?.status} onChange={(e)=> setOrderStatusChange(e, order_data?.order_id)}>
                                <option value='1'>Pending</option>
                                <option value='2'>Processing</option>
                                <option value='3'>Completed</option>
                                <option value='4'>Cancelled</option>
                            </Form.Select>
                            
                        </Form.Group>
                    </td>
                    <td>
                        {order_data?.payment_id}
                    </td>
                    <td className=''>
                        <div className='d-flex flex-shrink-0'>
                            <button onClick={() => viewDetails(order_data.order_id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='View Details'
                            >
                            <KTSVG
                                path='/media/icons/duotune/general/gen028.svg'
                                className='svg-icon-3'
                            />
                            </button>

                            <button onClick={() => uploadResultBtn(order_data.order_id)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Upload Result'
                            >
                            {/* <KTSVG
                                path='/media/icons/duotune/general/gen005.svg'
                                className='svg-icon-3'
                            /> */}
                            <Upload />
                            </button>
                            
                        </div>
                    </td>
                </tr>
                )
            })
        )
    }
    
    const getOrders = async () => {
        setContentLoading(true);
        
        if (searchQuery === '' && fromDate === undefined && toDate === undefined && sortBy === -1) {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/all`, { headers: {"Authorization" : ` ${token}`} })
            
            const data = res.data;
            const order_data = data.orders;
            const slice = order_data.slice(offset - 1 , offset - 1 + parseInt(ordersPerPage));
            const contactData = getOrderData(slice);

            setAllOrders(contactData);
            setPageCount(Math.ceil(data.total / ordersPerPage));
            setTotalItems(data.total);
        }else{
            getOrdersBySearch();
           
        }
        setContentLoading(false);
    }

    const getOrdersBySearch = async () => {
        // setContentLoading(true);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * ordersPerPage +1);
        // console.log(newOffset);
        setOffset(newOffset);
        setOrdersPerPage(ordersPerPage);
    };
    const viewDetails = (order_id) => {
        try{
            axios.get(`${process.env.REACT_APP_API_URL}/orders/order-details/${order_id}`, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data.orders;
                setOrderDetails(data);
                handleShow("", 'View Order Details', 'View Order Details', true);

            }
            )
        }catch(err){
            console.log(err);
        }
    }

    const handleShow = (e, title="View Order Details", button="View Order Details", viewMode=false) => {
        if(viewMode){
            setViewMode(true);
        }else{
            setViewMode(false);
        }
    
    // console.log(title, button);
        setModalTitle(title);
        setModalTitle(button);
        setShow(true);
    }
    const handleClose = () => {
        setOrderDetails([]);
        setShow(false);

    }

    const filehandleClose = () => {
        setFiles([]);

        setFileShow(false);
    }

    const formatDate = (date) => {
        // console.log(date);
        // Oct 19, 2022 9:00 AM
        const d = new Date(date);
        const year = d.getFullYear();
        const month = d.getMonth()+1;
        // month name
        const monthName = d.toLocaleString('default', { month: 'short' });

        const day = d.getDate();
        const hours = d.getHours();
        const minutes = d.getMinutes();
        const seconds = d.getSeconds();
        // hourse to 12 and add am pm
        const hours12 = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';

        const minutes0 = minutes < 10 ? `0${minutes}` : minutes;
        const hours0 = hours12 < 10 ? `0${hours12}` : hours12;
        const seconds0 = seconds < 10 ? `0${seconds}` : seconds;

        const formattedDate = `${monthName} ${day}, ${year} ${hours0}:${minutes0} ${ampm}`;
        return formattedDate;
    }   

    const setOrderStatusChange = (e, order_id) => {
        try{
            axios.patch(`${process.env.REACT_APP_API_URL}/orders/change-status`, {status: e.target.value, order_id: order_id}, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data;
                if(data.status === true){
                    toast.success(data.message);
                    handleClose();
                    getOrders();
                }
            }
            )
        }
        catch(err){
            console.log(err);
        }

    }
    const uploadResultBtn = (order_id) => {
        setFileShow(true);
        setOrderId(order_id);
    }

    const uploadResult = async (e) => {
        e.preventDefault();
        // check if file is not pdf
        if(files[0].file.type !== 'application/pdf'){
            toast.error('Please upload a pdf file');
            return;
        }
        const formData = new FormData();
        formData.append('order_id', orderId);
        formData.append('result', files[0].file);
        try{
            axios.post(`${process.env.REACT_APP_API_URL}/orders/upload-result`, formData, { headers: {"Authorization" : ` ${token}`} })
            .then(res => {
                const data = res.data;
                if(data.status === true){
                    toast.success(data.message);
                    filehandleClose();
                    getOrders();
                }
            }
            )
        }
        catch(err){
            console.log(err);
        }

    }

    async function downloadReport(order_id) {
        const { data } = await getDownloadablePdf(order_id)
        const blob = new Blob([data], { type: 'application/pdf' })
        saveAs(blob, "result.pdf")
    }

    async function getDownloadablePdf(order_id) {
        return axios.get(`${process.env.REACT_APP_API_URL}/orders/get-pdf-result/${order_id}`, {
            headers: {
            'Content-Type': 'multipart/form-data'
            },
            responseType: 'arraybuffer'
        })
    }
    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Orders</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Orders <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems<10?'0'+totalItems:totalItems}</span></span>
                    
                    </h3>
                    {/* <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                    <button
                        onClick={e=>handleShow(e, "Add Patient", "Add Patient")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Patient
                    </button>
                    </div> */}
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Order Number</th>
                                    <th className='min-w-150px'>Patient Name</th>
                                    <th className='min-w-150px'>Order Date</th>
                                    <th className='min-w-150px'>Order Status</th>
                                    {/* <th className='min-w-150px'>Order Total</th> */}
                                    <th className='min-w-150px'>Payment Id</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allOrders}
                            </tbody>
                        </table>

                        
                        <div className='text-center'>
                            {/* <span>{totalItems}</span> */}
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>

            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
            <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                {/* <Modal.Header closeButton >
                <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                </Modal.Header> */}
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center mb-13'>
                        <h1 className='mb-3'>{modalTitle}</h1>
                    </div>

                    <Form>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="TestName">
                                    <Form.Label>Order Number</Form.Label>: 
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.order_id} disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="TestName">
                                    <Form.Label>Order Date</Form.Label>:
                                    <Form.Control className='form-control-sm' type="text" value={formatDate(orderDetails[0]?.created_at)} disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='mt-5'>
                            <Form.Group controlId="TestName">
                                <Form.Label>Patient Name</Form.Label>: 
                                <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.patient.full_name} disabled={viewMode}/>
                            </Form.Group>
                        </div>

                        {/* options for parent test */}
                        
                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicPrice" className='mt-5'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.patient.email} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.patient.phone} required disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicStatus" className='mt-5'>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select aria-label="Default select example" className='form-select-sm' value={orderDetails[0]?.status} onChange={(e)=> setOrderStatusChange(e, orderDetails[0]?.order_id)}>
                                        <option value='1'>Pending</option>
                                        <option value='2'>Processing</option>
                                        <option value='3'>Completed</option>
                                        <option value='4'>Cancelled</option>
                                    </Form.Select>
                                    
                                </Form.Group>
                            </div>
                            <div className='col-md-6'>
                                <Form.Group controlId="formBasicStatus" className='mt-5'>
                                    <Form.Label>Payment Id</Form.Label>
                                    <Form.Control className='form-control-sm' type="text" value={orderDetails[0]?.payment_id} disabled={viewMode}/>
                                </Form.Group>
                            </div>
                        </div>

                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 border mt-5'>
                            <thead>
                                <tr className='fw-bolder text-muted bg-light'>
                                    <th></th>
                                    <th className='min-w-150px'>Test Name</th>
                                    <th className='min-w-150px'>Price</th>
                                    <th className='min-w-150px'>Quantity</th>
                                    <th className='min-w-150px'>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderDetails.map((item, index) => {
                                    // console.log(item);
                                    return (
                                        <tr key={index}>
                                            <td></td>
                                            <td>{item?.test_name}</td>
                                            <td>$ {item?.price}</td>
                                            <td>{item?.quantity}</td>
                                            <td>$ {item?.price * item?.quantity}</td>
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    
                    </Form>

                    {orderDetails[0]?.test_result || orderDetails[0]?.test_result !== null || orderDetails[0]?.test_result !== '' ?
                        <div className='row pb-4'>
                            <div className='col-md-6'>
                                <Form.Group controlId="TestName">
                                    <Form.Label>Test Result</Form.Label>: &nbsp;
                                    <button onClick={e=>downloadReport(orderDetails[0]?._id)}
                                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='Download Report'
                                        >
                                        <CloudDownloadFill />
                                    </button>
                                </Form.Group>
                            </div>
                            
                        </div>: ''}

                </Modal.Body>
                    {/* modal footer */}
                    {viewMode ? '': (
                        <div className='modal-footer d-flex justify-content-center pt-4'>
                            <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                Close
                            </button>
                            <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" >
                                {modalTitle}
                            </button>
                        </div>
                    )}
            </Modal>

            <Modal onHide={filehandleClose} className='modal fade' size="md" show={filelgshow}>
                {/* <Modal.Header closeButton >
                <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                </Modal.Header> */}
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={filehandleClose}>
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center mb-13'>
                        <h1 className='mb-3'>{filemodalTitle}</h1>
                    </div>
                    <Form onSubmit={uploadResult} >
                    <FilePond
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={false}
                            maxFiles={3}
                            
                            // server="/api"
                            name="files" /* sets the file input name, it's filepond by default */
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                        />
                        </Form>
                </Modal.Body>
                    {/* modal footer */}
                    {viewMode ? '': (
                        <div className='modal-footer d-flex justify-content-center pt-4'>
                            <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={filehandleClose}>
                                Close
                            </button>
                            <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={uploadResult}>
                                {filemodalTitle}
                            </button>
                        </div>
                    )}
            </Modal>
        </>
    )
}

export default Orders
