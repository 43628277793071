import {useEffect, useState} from 'react'
import {PageTitle} from '../../_metronic/layout/core'
import {KTSVG} from '../../_metronic/helpers'
import { Form } from 'react-bootstrap'
import axios from 'axios'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'


const Patients = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token') //'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2FhOTkyYTM3NmI4Y2UxOWYwMzE5NmQiLCJpYXQiOjE2NzIxMjQ3NDl9.GVST69iPilsjJgfTp9os8Gr-9KyKEf4c5LvbleQss74'
    
    useEffect(() => {
        getTests();
    }, [])
    
    const [modalTitle, setModalTitle] = useState('Add Patient');
    const [lgshow, setShow] = useState(false);

    // const editorRefLong = useRef(null);
    


    const [patient_id, setPatientId] = useState(0);
    const [patient_name, setPatientName] = useState("");
    const [patient_status, setPatientStatus] = useState(1);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");
    const [dob, setDob] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    
    const [count, setCount] = useState(0);
   
    const [validated, setValidated] = useState(false);
    const [fileSizeErrorPhoto , setFileSizeErrorPhoto] = useState(false);
    const [fileFormatError, setFileFormatError] = useState(false);
    const [isEditPhoto, setIsEditPhoto] = useState(false);

    const [patients, setPatients] = useState([]);

    const [viewMode, setViewMode] = useState(false);

    const getTests = async () => {
        axios.get(`${process.env.REACT_APP_API_URL}/patientuser/all_patients`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
                setPatients(res.data.data);
                setCount(res.data.total);
            }
        ).catch(err => {
            console.log(err);
        })
    }


    const handleSubmit = (e) => {
        const form = e.currentTarget;
        if(form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        if(form.checkValidity() === true){
            e.preventDefault();
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = `<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${btn.innerHTML}`;
            btn.disabled = true;

            if(patient_id !== 0){
                const data = {
                    
                }
                updateCategory(data);
            
            }else{
                const data = {
                    
                }
                addCategory(data);
            }
        }
    }

    const addCategory = async (data) => {
        const formData = new FormData();
        

        axios.post(`${process.env.REACT_APP_API_URL}/patientuser`, formData,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                toast.success('Patient added successfully');
                handleClose();
                getTests();

            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        }
        )
        .finally(() => {
            const btn = document.getElementById("btn-submit");
            btn.innerHTML = modalTitle;
            btn.disabled = false;
        })
    }

    const updateCategory = async (data) => {
        // const fileName = test_photo.split('/').pop();
        const formData = new FormData();
        
        // if(test_photo_selected.name != undefined && fileName != test_photo_selected.name){
        //     formData.append('photo', test_photo_selected);
        // }

        axios.patch(`${process.env.REACT_APP_API_URL}/patientuser/${data.patient_id}`, formData,{ headers: {"Authorization" : ` ${token}`}})
            .then(res => {
                toast.success('Patient updated successfully');
                handleClose();
                getTests();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
    }
    
    const handlePhoto = (e) => {
        setFileSizeErrorPhoto(false);
        setFileFormatError(false);

        if(e.target.files[0].size > 300000){
            setFileSizeErrorPhoto(true);
            e.target.value = null;
            return;
        }else{
            if(e.target.files[0].type !== 'image/jpeg' && e.target.files[0].type !== 'image/png' && e.target.files[0].type !== 'image/jpg' && e.target.files[0].type !== 'image/svg' && e.target.files[0].type !== 'image/svg+xml'  && e.target.files[0].type !== 'image/webp'){
                // toast.error('File format not supported');
                setFileFormatError(true);
                e.target.value = null;
                return;
            }else{
                // seTestPhotoSelected(e.target.files[0]);
                setIsEditPhoto(false)
            }
        }
    }

    const handleShow = (e, title="Add Patient", button="Add Patient", viewMode=false) => {
            if(viewMode){
                setViewMode(true);
            }else{
                setViewMode(false);
            }
        
        // console.log(title, button);
            setModalTitle(title);
            setModalTitle(button);
            setShow(true);
    }

    const handleClose = () => {
        setValidated(false);
        setPatientId(0);
        setPatientName("");
        setPatientStatus(1);
        setFileSizeErrorPhoto(false);
        setFileFormatError(false);
        setIsEditPhoto(false);
        
        setShow(false);

    }

    const viewDetails = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/patientuser/admin/profile/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            setIsEditPhoto(true);
            setPatientId(res.data._id);
            setPatientName(res.data.full_name);
            setEmail(res.data.email);
            setPatientStatus(res.data.status);
            setGender(res.data.gender);
            setCity(res.data.city);
            setState(res.data.state);
            setAddress(res.data.address);
            setZip(res.data.zip);
            setPhone(res.data.phone);
            setDob(res.data.dob);
            handleShow("", 'View Details', 'View Details', true);
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const editPatient = async (id) => {
        axios.get(`${process.env.REACT_APP_API_URL}/patientuser/${id}`,{ headers: {"Authorization" : ` ${token}`}}).then(res => {
            
            setIsEditPhoto(true);
            
            handleShow("", 'Edit Patient', 'Update Patient');
        }
        ).catch(err => {
            console.log(err);
        })
    }


    const deletePatient = async (id) => {
        // confirm
        const confirm = window.confirm('Are you sure you want to delete this patient?');
        if(confirm){
            axios.delete(`${process.env.REACT_APP_API_URL}/patientuser/delete_user/${id}`,{ headers: {"Authorization": ` ${token}`}})
            .then(res => {
                if(res.data.status === true){
                    toast.success(res.data.message);
                }else{
                    toast.error(res.data.message);
                }
                getTests();
            }
        ).catch(err => {
            console.log(err);
            toast.error('Something went wrong');
        })
        }
    }
        

    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Patients</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Total Patients <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{count<10?'0'+count:count}</span></span>
                    
                    </h3>
                    {/* <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                    >
                    <button
                        onClick={e=>handleShow(e, "Add Patient", "Add Patient")}
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#category_modal'
                    >
                        <KTSVG path='media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
                        New Patient
                    </button>
                    </div> */}
                </div>
                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                            className='form-check-input'
                                            disabled
                                            type='checkbox'
                                            value='1'
                                            data-kt-check='true'
                                            data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th className='min-w-150px'>Patient Name</th>
                                    <th className='min-w-140px'>Email</th>
                                    <th className='min-w-140px'>Phone</th>
                                    <th className='min-w-50px'>Status</th>
                                    <th className='min-w-50px'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {patients.map((patient, index) => {
                                    
                                    if(patient.status === 1){
                                        var patient_status = 'Active'
                                        var status = 'success'
                                    }else{
                                        var patient_status = 'Inactive'  
                                        var status = 'danger' 
                                    }
                                    return (

                                    
                                <tr key={index} id={`row`+patient._id}>
                                    <td>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input className='form-check-input widget-9-check' disabled type='checkbox' value='1' />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <div className='d-flex justify-content-start flex-column'>
                                                {/* <a href='#' className='text-dark fw-bold text-hover-primary fs-6'> */}
                                                    {patient.full_name}
                                                {/* </a> */}
                                            </div>
                                        </div>
                                    </td>
                                   
                                    <td>
                                      {patient.email} 
                                    </td>
                                    <td>
                                      {patient.phone} 
                                    </td>
                                    <td className=''>
                                        <span className={`badge badge-light-`+status}>{patient_status}</span>
                                    </td>
                                    <td className=''>
                                        <div className='d-flex flex-shrink-0'>
                                            <button onClick={() => viewDetails(patient._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen028.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                            {/* <button onClick={() => editPatient(patient._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                            >
                                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                            </button> */}
                                            <button onClick={() => deletePatient(patient._id)}
                                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                                            >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen027.svg'
                                                className='svg-icon-3'
                                            />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                    )
                                    })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
                <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                    {/* <Modal.Header closeButton >
                    <Modal.Title className='h5'>{modalTitle}</Modal.Title>
                    </Modal.Header> */}
                    <div className='modal-header pb-0 border-0 justify-content-end'>
                        <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' onClick={handleClose}>
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                        </div>
                    </div>
                    <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                        <div className='text-center mb-13'>
                            <h1 className='mb-3'>{modalTitle}</h1>
                        </div>

                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Form.Group controlId="TestName">
                                <Form.Label>Patient Name <span className='text-danger'>*</span></Form.Label>
                                <Form.Control className='form-control-sm' type="test" placeholder="Enter Patient Name" value={patient_name} onChange={(e)=> setPatientName(e.target.value)} required disabled={viewMode}/>
                                {/* <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text> */}
                            </Form.Group>
                            {/* options for parent test */}
                            
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPrice" className='mt-5'>
                                        <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Email" value={email} onChange={(e)=> setEmail(e.target.value)} required disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Phone" value={phone} onChange={(e)=> setPhone(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPrice" className='mt-5'>
                                        <Form.Label>Gender <span className='text-danger'>*</span></Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm'
                                            value={gender} onChange={(e)=> setGender(e.target.value)} required disabled={viewMode}
                                            >
                                            <option value=''>Select Gender...</option>
                                            <option value='1'>Male</option>
                                            <option value='2'>Female</option>
                                            <option value='3'>Other</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                        <Form.Label>Date of Birth</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="" value={dob} onChange={(e)=> setDob(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPrice" className='mt-5'>
                                        <Form.Label>Address <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Email" value={address} onChange={(e)=> setAddress(e.target.value)} required disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                        <Form.Label>City</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Phone" value={city} onChange={(e)=> setCity(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPrice" className='mt-5'>
                                        <Form.Label>State <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Email" value={state} onChange={(e)=> setState(e.target.value)} required disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicOldPrice" className='mt-5'>
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control className='form-control-sm' type="text" placeholder="Enter Phone" value={zip} onChange={(e)=> setZip(e.target.value)} disabled={viewMode}/>
                                    </Form.Group>
                                </div>
                            </div>


                            {/* <Form.Group controlId="formBasicPhoto" className='mt-5 mb-2'>
                                <Form.Label>Photo</Form.Label>
                                
                                <Form.Text className="text-muted">
                                    {fileSizeErrorPhoto && <div className='text-danger'>(File size must be less than 300kb)</div>}
                                    {fileFormatError && <div className='text-danger'>(File must be jpg/png/svg)</div>}
                                    
                                </Form.Text>
                                {viewMode == true ? '' :
                                    <Form.Control className='form-control-sm' type="file" placeholder="Enter Category Name" onChange={handlePhoto} />
                                }
                            </Form.Group>
                            {
                                isEditPhoto?(
                                    <img src={toAbsoluteUrl(test_photo_selected)} alt={test_name} width="100px" height="100px" className='mb-3' />
                                ):(
                                    ''
                                )
                            } */}

                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group controlId="formBasicStatus" className='mt-5'>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={patient_status} onChange={(e)=> setPatientStatus(e.target.value)} disabled={viewMode}>
                                            <option value='1'>Active</option>
                                            <option value='0'>Inactive</option>
                                        </Form.Select>
                                        
                                    </Form.Group>
                                </div>
                                {/* <div className='col-md-6'>
                                    <Form.Group controlId="formBasicPopular" className='mt-5'>
                                        <Form.Label>Popular</Form.Label>
                                        <Form.Select aria-label="Default select example" className='form-select-sm' value={isPopular} onChange={(e)=> setIsPopular(e.target.value)} disabled={viewMode}>
                                            <option value='1'>Yes</option>
                                            <option value='0'>No</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div> */}
                            </div>

                        
                        </Form>
                    </Modal.Body>
                        {/* modal footer */}
                        {viewMode ? '': (
                            <div className='modal-footer d-flex justify-content-center pt-4'>
                                <button type='button' className='btn btn-sm btn-flex btn-light btn-active-light-primary me-2' onClick={handleClose}>
                                    Close
                                </button>
                                <button type='button' className='btn btn-sm btn-flex btn-primary' id="btn-submit" onClick={handleSubmit}>
                                    {modalTitle}
                                </button>
                            </div>
                        )}
                    </Modal>

        </>
    )
}

export default Patients