import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import Categories from '../pages/Categories'
import Tests from '../pages/Tests'
import Patients from '../pages/Patients'
import Orders from '../pages/Orders'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'


const PrivateRoutes = () => {
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper />} />
                <Route path='categories' element={<Categories />} />
                <Route path='tests' element={<Tests />} />
                <Route path='patients' element={<Patients />} />
                <Route path='orders' element={<Orders />} />
                <Route path='menu-test' element={<MenuTestPage />} /> 

                {/* Lazy Modules */}
                <Route
                path='crafted/account/*'
                element={
                    <SuspensedView>
                    <AccountPage />
                    </SuspensedView>
                }
                />
                
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
        '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
